.pdf-main-wrapper{
    max-width: 840px;
    margin: 0 auto;
    padding: 0px 15px;
    page-break-after: avoid;
    page-break-inside:avoid;
}
.chronic-care-title{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 20px;
    color: #000;
}
.pdf-logobar{
    margin-top: 15px;
    margin-bottom: 15px;
}
.pdf-logobar img{
    width: 300px;
}
.chronic-care-content{
    font-size: 16px;
}
.common-heading-wrapper{
    background-color: #00549E !important;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 6px;
}
.common-heading-title{
    color: #fff !important;
    font-weight: 700;
    font-size: 16px;
}
.pdf-main-wrapper th, .pdf-main-wrapper td{
    border-color: #000 !important;
    height: 27px;
    padding: 4px !important;
    vertical-align: middle !important;
}
.pdf-main-wrapper input{
    height: 27px;
    width: 100%;
    border: transparent;
    background-color: transparent;
    padding-left: 10px;
}
.pdf-main-wrapper tr:nth-child(odd) {background-color: #DFECF7 !important;}
.table-label-input{
    display: flex;
    align-items: center;
    gap: 5px;
}
.pdf-hr {
    height: 10px !important;
    background-color: #ff934a !important;
}
.pdf-third-table td{
    height: 62px !important;
}
.pdf-light-font{
    font-weight: 500;
    color: #fff !important;
}
.table-responsive {
    display: table;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table {
    margin-bottom: 14px;
}

.h4, .h5, .h6, h4, h5, h6 {
    margin-top: 10px;
    margin-bottom: 10px;
}
/* 
.table th, .table td {
    vertical-align: middle;
    font-size: 0.875rem;
    line-height: 1;
    font-family: "ubuntu-medium", sans-serif;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    color: #333 !important;
} */


/* .pdf-main-wrapper input{
    height: 31px;
    width: 100% !important;    
    background-color: #ffffff;
    padding-left: 3px;
    border: 1px solid rgb(223 236 247);
    border-radius: 3px;
    outline: none;
} */

@media print {
    .pagebreak {
        clear: both;
        overflow: visible !important;
        page-break-after: always;
    }
    @page {size: portrait; margin: 0 0 0 0}
        body {
            -webkit-print-color-adjust: exact;
        }
}

.display-error-messages {
    padding: 0px !important;
    margin-top: 5px !important;
    margin-bottom: 0px !important;
    color: red;
    font-size: 13px;
    margin-left: 0px !important;
}

.initiation-date-css {
    border-bottom: "1px solid #333";
    width: "150px";
    outline: "none"
}

.input-box-style {
    height: 31px !important;
    width: 100% !important;
    padding-left: 3px !important;
    border: 1px solid rgb(163 163 163 / 0%) !important;
    border-radius: 3px !important;
    outline: none !important;
}
.radio-label {
    padding: 0px;
    margin: 0px;
    font-size: 17px;
}

.display-table {
    display: table;
}

.input-box-style-editbale {    
    width: 100% !important;
    background-color: #ffffff !important;
    padding-left: 3px !important;
    border: 1px solid rgb(163 163 163) !important;
    border-radius: 3px !important;
    outline: none !important;
    padding-left: 5px !important;
}

.input-box-style-editbale-disable {
    height: 34px !important;
    width: 100% !important;
    background-color: #ffffff00 !important;
    padding-left: 3px !important;
    border: none !important;
    border-radius: 3px !important;
    outline: none !important;
    pointer-events:none;
    padding-left: 5px !important;
}
input[type="date"].input-box-style-editbale,
input[type="date"].input-box-style-editbale-disable {
    width: auto !important;
}

.input-box-style-editbale-disable-radio {
    pointer-events:none;
}

.img-ccm-form {    
    width: 300px !important;
    height: 52px !important;
    border-radius: 0px !important;
}

.logs-view {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.3125rem;
    border-radius: 12px;
    box-shadow: rgb(185 185 185 / 43%) 0px 2px 4px 0px, rgb(211 211 211 / 20%) 0px 6px 15px 14px;
    margin-top: 15px;
}

.mobile-view {
    width: 100%;
    max-width: fit-content;
}

.input-border {
    width: 100%;
    margin-bottom: 0px;
    padding-top: 6px;
    border: 1px solid #ababab !important;
    border-radius: 4px !important;
    margin-bottom: 7px;
    min-height: 38px !important;
    justify-items: center;
}

.view-mode-display-value {
    min-height: 35px;
    display: flex;
    align-items: center;
    font-weight: 100;
}
.view-mode-display-value-bold {
    min-height: 35px;
    display: flex;
    align-items: center;
    font-weight: 600;
    color: black;
}

.ptag-border-letter-spacing {
    border: 1px solid #c7c7c7;
    padding: 5px;
    border-radius: 3px;
    letter-spacing: 0.9px;
    min-width: 110px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: left;
}

.ptag-border {
    border: 1px solid #c7c7c7;
    padding: 5px;
    border-radius: 3px;
}
.pdf-main-wrapper{
    max-width: 840px;
    margin: 0 auto;
    padding: 0px 15px;
    page-break-after: avoid;
    page-break-inside:avoid;
}
.chronic-care-title{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 20px;
    color: #000;
}
.pdf-logobar{
    margin-top: 15px;
    margin-bottom: 15px;
}
.pdf-logobar img{
    width: 300px;
}
.chronic-care-content{
    font-size: 16px;
}
.common-heading-wrapper{
    background-color: #00549E !important;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 6px;
}
.common-heading-title{
    color: #fff !important;
    font-weight: 700;
    font-size: 16px;
}
.pdf-main-wrapper th, .pdf-main-wrapper td{
    border-color: #000 !important;
    height: 27px;
    padding: 4px !important;
    vertical-align: middle !important;
}
.pdf-main-wrapper input{
    height: 27px;
    width: 100%;
    border: transparent;
    background-color: transparent;
    padding-left: 10px;
}
.pdf-main-wrapper tr:nth-child(odd) {background-color: #DFECF7 !important;}
.table-label-input{
    display: flex;
    align-items: center;
    gap: 5px;
}
.pdf-hr {
    height: 10px !important;
    background-color: #ff934a !important;
}
.pdf-third-table td{
    height: 62px !important;
}
.pdf-light-font{
    font-weight: 500;
    color: #fff !important;
}
.table-responsive {
    display: table;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table {
    margin-bottom: 14px;
}

.h4, .h5, .h6, h4, h5, h6 {
    margin-top: 10px;
    margin-bottom: 10px;
}
/* 
.table th, .table td {
    vertical-align: middle;
    font-size: 0.875rem;
    line-height: 1;
    font-family: "ubuntu-medium", sans-serif;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    color: #333 !important;
} */


/* .pdf-main-wrapper input{
    height: 31px;
    width: 100% !important;    
    background-color: #ffffff;
    padding-left: 3px;
    border: 1px solid rgb(223 236 247);
    border-radius: 3px;
    outline: none;
} */

@media print {
    .pagebreak {
        clear: both;
        page-break-after: always;
    }
    @page {size: portrait; margin: 0 0 0 0}
        body {
            -webkit-print-color-adjust: exact;
        }
}

.display-error-messages {
    padding: 0px !important;
    margin-top: 5px !important;
    margin-bottom: 0px !important;
    color: red;
    font-size: 13px;
    margin-left: 0px !important;
}

.initiation-date-css {
    border-bottom: "1px solid #333";
    width: "150px";
    outline: "none"
}

.input-box-style {
    height: 31px !important;
    width: 100% !important;
    padding-left: 3px !important;
    border: 1px solid rgb(163 163 163 / 0%) !important;
    border-radius: 3px !important;
    outline: none !important;
}
.radio-label {
    padding: 0px;
    margin: 0px;
    font-size: 17px;
}

.display-table {
    display: table;
}

.input-box-style-editbale {    
    width: 100% !important;
    background-color: #ffffff !important;
    padding-left: 3px !important;
    border: 1px solid rgb(163 163 163) !important;
    border-radius: 3px !important;
    outline: none !important;
}

.input-box-style-editbale-disable {
    height: 31px !important;
    width: 100% !important;
    background-color: #ffffff00 !important;
    padding-left: 3px !important;
    border: none !important;
    border-radius: 3px !important;
    outline: none !important;
    pointer-events:none;
}
.input-box-style-editbale-disable-radio {
    pointer-events:none;
}

.img-ccm-form {    
    width: 300px !important;
    height: 52px !important;
    border-radius: 0px !important;
}

.logs-view {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.3125rem;
    border-radius: 12px;
    box-shadow: rgb(185 185 185 / 43%) 0px 2px 4px 0px, rgb(211 211 211 / 20%) 0px 6px 15px 14px;
    margin-top: 15px;
}

.mobile-view {
    width: 100%;
    max-width: fit-content;
}

.div-border {
    border: 1px solid #c8c8c8;
    border-radius: 9px;
    margin-right: 9px;
    margin-left: 5px;
    margin: 5px;
    padding: 7px;
    background: #f8f8f8;
}

.remove-padding {
    padding: 5px !important;
}
.div-remove-padding {
    padding: 0px !important;
}

.label-center {
    text-align: center;
    display: flex;
    align-items: center;
}

.note-text {
    color: #434343;
    font-size: 14px;
    font-family: "ubuntu-medium", sans-serif;
    margin-top: 11px;
}

.input-box-style-editbale-muliti-line {    
    width: 100% !important;
    background-color: #ffffff !important;
    padding-left: 3px !important;
    border: 1px solid rgb(163 163 163) !important;
    border-radius: 3px !important;
    outline: none !important;
}

.input-box-style-editbale-muliti-line-disable {
    width: 100% !important;
    background-color: #ffffff00 !important;
    padding-left: 3px !important;
    border: none !important;
    border-radius: 3px !important;
    outline: none !important;
    pointer-events:none;
}
.input-box-style-editbale-muliti-line-disable-radio {
    pointer-events:none;
    background-color: #e1e1e1 !important;
}

.answer-print-border {
    margin: 7px;
    border: 3px solid #2196F3;
    padding: 13px;
}

.view-ccm-answer-patient {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.expand-selected-style {
    background: #f4f4f4;
    border: 3px solid #e9e9e9;
}
.print-label-font-size-small {
    margin-top: 0px !important;
    font-size: 14px;
}

.print-margin {
    margin-right: 4px;
}

.display-center {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #434343;
}

.submit-div {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100% !important;
}

.add-Condition-click-cls {
    background: #607D8B !important;
    padding: 10px 10px;
    border-radius: 8px;
    border: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  
  .add-Condition-click-cls .mdi-plus {
    margin-right: 4px;
    font-weight: 600;
  }

  .delete-condtion-click-cls {
    background: #dc3545 !important;
    padding: 7px 12px;
    border-radius: 5px;
    border: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.delete-condition-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.add-condition-div {
    display: grid;
    justify-content: end;
    align-items: end;
}

/* Configration CSS */
.configration-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px 8px 0px 0px;
}
.header-text {
    color: #151515 !important;
    font-size: 16px !important;
}

.div-width-full {
    width: 99% !important;
}

.add-email-input {
    background: #415864 !important;
    padding: 6px 9px;
    border-radius: 5px;
    border: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.add-email-input .mdi-plus {
    margin-right: 4px;
    font-weight: 600;
}

.display-align-justify-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.configration-header-back {
    padding: 8px;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
}

.save-configration {
    background: #4CAF50 !important;
    padding: 8px 13px;
    border-radius: 6px;
    border: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.close-configration {
    background: #f44336 !important;
    padding: 8px 13px;
    border-radius: 6px;
    border: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.edit-configration {
    background: #607D8B !important;
    padding: 8px 13px;
    border-radius: 6px;
    border: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.draw-div-line {
    background: #efefef;
    margin: 11px 0px;
    height: 2px;
    border-radius: 5px;
}

.remove-margin {
    margin: 0px !important;
}

.pdf-main-wrapper-with-out-pagebrack{
    page-break-after: avoid;
    page-break-inside:avoid;
}