.chat-online {
    color: #34ce57
}

.chat-offline {
    color: #e4606d
}

.chat-messages {
    display: flex;
    flex-direction: column;
    max-height: 550px;
    overflow-y: auto
}

.chat-message-left,
.chat-message-right {
    display: flex;
    flex-shrink: 0
}

.chat-message-left {
    margin-right: auto;
    width: 50%;
}

.chat-message-right {
    flex-direction: row-reverse;
    margin-left: auto;
    width: 50%;
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.border-top {
    border-top: 1px solid #dee2e6 !important;
}

.right-date-time {
    margin: 5px !important;
}

.display-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.display-center-message-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.padding-margin-sent {
    padding: 20px 0px 0px 0px !important;
    margin: 0px !important;
}

.padding-margin-zero {
    padding: 0px !important;
    margin: 0px !important;
    margin-left: 15px !important;
}

/* APPROVED PATIENT BUTTON */
.sent-message-click {
    background: #2088e5 !important;
    border-radius: 0px 6px 6px 1px;
    border: transparent;
    color: #ffffff !important;
    width: 100px;
    font-size: 15px;
    font-weight: 600;
}

.sent-message-click .mdi-checkbox-marked-circle-outline {
    margin-right: 4px;
    font-weight: 600;
    color: white;
    font-size: 18px;
}

.bg-light-sender {
    background-color: #eaeaeab5 !important;
}

.bg-light-receiver {
    background-color: #e7eef6 !important
}

.message-time {
    color: black !important;
}

.clear-search-btn {
    background: #dc3545 !important;
    border-radius: 0px 6px 6px 1px;
    border: transparent;
    color: #ffffff !important;
    width: 142px;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clear-search-btn .mdi-close {
    margin-right: 4px;
    font-weight: 600;
}

.disable {
    cursor: not-allowed;
    pointer-events: none;
    background: #cbcbcb !important;
    border-radius: 0px 6px 6px 1px;
    border: transparent;
    color: #ffffff !important;
    width: 100px;
    font-size: 15px;
    font-weight: 600;
}