body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f8f8f8 !important;
}
.DayPickerInput {
  display: block !important;
}
input.form-control {
      padding: 1.2rem 1.375rem !important;
}
.custom-switch .custom-control-input:checked~.custom-control-label:after {
  background-color: white !important;
}
/* .form-control{
  height: auto !important;
  border-bottom: 0px !important
} */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;  
  background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat calc(100% - 10px) !important; /* Better placement regardless of input width */
}
/*For IE*/
select::-ms-expand { display: none; }

 @media print {
  .break-page-reading-type {page-break-inside: avoid;}
  /* .page-break-ka-after {page-break-after: always} */
}
.content-wrapper {
  background-color: #f8f8f8 !important;
}

.navbar {
    background: #fff;
    height: 120px;
    padding: 1.5rem 0 !important;
    box-shadow: 0 1px 15px rgba(0,0,0,.04), 0 1px 6px rgba(0,0,0,.04) !important;
}
.navbar .navbar-brands {
  position: absolute !important;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}
/* Mobile responsive code for navbar logo */
@media (max-width: 1439px){
  .navbar .navbar-brands {
    position: absolute !important;
    left: 0;
    right: 30%;
    margin: 0 auto;
    text-align: center;
  }
}
.navbar .img-fluidheight {
  max-width: 100%;
  height: 120px;    
  padding: 7px;
 
}
.img-tabsfluid {
  max-width: 100%;
  height: 40px;    
}
@media (max-width: 1439px)
{
  .navbar .img-fluidheight {
    max-width: 100%;
    height: 90px;
    padding: 7px;  
  }
  .navbar {
    height: 90px !important;
}
}

@media (max-width: 1199px)
{
  .navbar .img-fluidheight {
    max-width: 100%;
    height: 80px;
    padding: 7px;
  }
  .navbar {
    height: 80px !important;
    padding: 1rem 0 !important;
}
}
@media (max-width: 767px)
{
  .navbar .img-fluidheight {
    max-width: 100%;
    height: 70px;
    padding: 7px;
  }
  .navbar {
    height: 70px !important;
    padding: 10px 0 !important;
}
}
.navbar.default-layout-navbar .navbar-menu-wrapper {
  width: 100% !important;
}

.main-menu::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.main-menu::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.main-menu::-webkit-scrollbar-thumb {
  background: #00e4d0; 
}

/* Handle on hover */
.main-menu::-webkit-scrollbar-thumb:hover {
  background: #00e4d0; 
}

/* 

.navbar .navbar-right {
  flex-basis: 40%;
  text-align: right;
} */

@media (max-width: 767px)
{
  .sidebar {
    padding-top: 70px;
    border-left: 1px solid #f3f3f3;
    box-shadow: 0 3px 30px rgba(0,0,0,.1), 0 3px 20px rgba(0,0,0,.1);
}
}




.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    padding-top: 120px;
    z-index: 4;

    
    /* height: calc(100% - 120px); */
}

.sidebar .main-menu ul li a:focus, .sidebar .main-menu ul li a:hover {
  color:#198ae3 !important;
  background: #f8f8f8 !important;
}

.sidebar .main-menu {
  width: 120px;
  overflow-y: auto;
  height: calc(100% - 120px);
  background: #fff;
  z-index: 3;
  position: fixed;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s,-webkit-transform .2s;
  padding-top: 2px;
  border-left: 1px solid #f3f3f3;
  box-shadow: 0 3px 30px rgba(0,0,0,.1), 0 3px 20px rgba(0,0,0,.1);
  left: 0;
}
@media (max-width: 1439px)
{
  .sidebar,.page-body-wrapper {
    padding-top: 90px !important;
}
.page-body-wrapper {
  min-height: calc(100vh - 90px)!important;
}
}
@media (max-width: 1439px)
{
  .sidebar .main-menu {
    /* width: 110px; */
    height: calc(100% - 90px);
}
}
@media (max-width: 1199px)
{
  .sidebar .main-menu {
    /* width: 100px; */
    height: calc(100% - 80px);
}
}
@media (max-width: 1199px)
{
  .sidebar, .page-body-wrapper {
    padding-top: 80px !important;
}
.page-body-wrapper {
  min-height: calc(100vh - 80px)!important;
}
}
@media (max-width: 767px)
{
  .sidebar .main-menu {
    /* width: 90px; */
    height: calc(100% - 70px);
}
.sidebar, .page-body-wrapper {
  padding-top: 70px !important;
}
.page-body-wrapper {
  min-height: calc(100vh - 70px)!important;
}
}




.sidebar .main-menu .scroll {
  padding-right: unset;
  margin-right: unset;
  height: 100%;
}
.sidebar .scrollbar-container {
  margin-right: 0;
  padding-right: 0;
}
.scrollbar-container {
  margin-right: -15px;
  padding-right: 15px;
  position: relative;
}
.scrollbar-container {
  position: relative;
  height: 100%;
}
.flex-column {
  flex-direction: column!important;
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.list-inline, .list-unstyled {
  padding-left: 0;
  list-style: none;
}
.sidebar .main-menu ul li {
  position: relative;
}
.sidebar .main-menu ul li.active a {
  color:#198ae3;
}
@media (max-width: 767px)
{
  .sidebar .main-menu ul li a {
    height: 90px;
  }
}

.sidebar .main-menu ul li a {
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  border-bottom: 1px solid #f3f3f3;
  color: #212121;
  -webkit-transition: color .2s;
  transition: color .2s;
  -webkit-transition: background .2s;
  transition: background .2s;
}
@media (max-width: 767px)
{
  .sidebar .main-menu ul li i {
    font-size: 28px;
    line-height: 38px;
}
}


.sidebar .main-menu ul li i {
    font-size: 32px;
    line-height: 42px;
}
.sidebar .main-menu ul li span {
  text-align: center !important;
  padding: 0 10px !important;
  line-height: 14px !important;
}





@media (max-width: 1439px)
{
  .menu-hidden .main-panel, .menu-sub-hidden .main-panel, .sub-hidden .main-panel {
    margin-left: 120px;
    margin-top: 0px;
}
.main-panel {
  margin: 0 0 0 380px;
}

}
.menu-hidden .main-panel, .menu-sub-hidden .main-panel, .sub-hidden .main-panel {
  margin-left: 120px;
}





@media (max-width: 1199px)
{
  .menu-hidden .main-panel, .menu-sub-hidden .main-panel, .sub-hidden .main-panel {
    margin-left: 120px;
}
}
@media (max-width: 1199px)
{
  .menu-hidden .main-panel, .menu-sub-hidden .main-panel, .sub-hidden .main-panel {
    margin-left: 120px;
}
.main-panel {
  margin: 0 0 0 340px;
}
}
@media (max-width: 767px)
{
  .main-panel {
    margin-bottom: 0;
}
}

@media (max-width: 575px)
{
  .main-panel {
    margin-bottom: 0;
}
}







.sidebar .main-menu ul li.active:after {
  content: " ";
  background:#00dfd4;
  border-radius: 10px;
  position: absolute;
  width: 6px;
  height: 90px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
}



@media (max-width: 1439px)

{
  .main-show-temporary .main-menu {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}
}

@media (max-width: 1199px)
{
  .main-show-temporary .main-menu {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}
}




.main-show-temporary .main-menu {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}
.menu-mobile .main-menu {
    -webkit-transform: translateX(-90px);
    transform: translateX(-90px);
}




.navbar.default-layout-navbar .navbar-menu-wrapper {
  
  padding-left: 40px !important;
}
@media (max-width: 1439px)

{
  .navbar.default-layout-navbar .navbar-menu-wrapper {
  
    padding-left: 30px !important;
  }
}
@media (max-width: 1199px)

{
  .navbar.default-layout-navbar .navbar-menu-wrapper {
  
    padding-left: 20px !important;
  }
}
@media (max-width: 767px)

{
  .navbar.default-layout-navbar .navbar-menu-wrapper {
  
    padding-left: 0px !important;
  }
}
@media (max-width: 575px)

{
  .navbar.default-layout-navbar .navbar-menu-wrapper {
  
    padding-left: 0px !important;
  }
}
@media (max-width: 1439px)
{.main-hidden .main-menu, .menu-hidden .main-menu {
    -webkit-transform: translateX(-110px);
    transform: translateX(-110px);
}}
@media (max-width: 1199px)
{
  .main-hidden .main-menu, .menu-hidden .main-menu {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
}
}


.main-hidden .main-menu, .menu-hidden .main-menu {
    -webkit-transform: translateX(-120px);
    transform: translateX(-120px);
}
@media (max-width: 1439px){
  .main-hidden .main-panel{
      margin-left: 0px;
  }}

@media (max-width: 1199px){
.main-hidden .main-panel {
    margin-left: 0px;
}}
@media (max-width: 1199px){
.menu-hidden .main-panel {
    margin-left: 0px;
}}

.main-hidden .main-panel {
    margin-left: 0px;
}
.menu-hidden .main-panel {
    margin-left: 0px;
}
.main-panel {
  -webkit-transition: margin-left .2s;
  transition: margin-left .2s;
}
 .purchase-popup{
  /* background: linear-gradient(120deg, #00e4d0, #5983e8) !important; */
  background: transparent !important;
  padding: 20px
}
.col-12 .page-header {
  display: none !important
}
.page-title {
  color: white !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.page-title-icon, .breadcrumb {
  visibility: hidden !important;
}
.justify-content-center.nav.nav-tabs {
  background: linear-gradient(120deg,  #5983e8,#1e88e5, #1e88e5, #00c2e4, #00e4d0) !important;
}
.card-body .justify-content-center.nav.nav-tabs {
  margin: auto -2.5rem;
  background: linear-gradient(120deg, #5983e8, #1e88e5, #1e88e5, #00c2e4 , #00e4d0) !important;
}
.card-body.pt-2 {
  padding-top: 0px !important;
}
.project-tab #tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: white  !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  border-bottom: 5px solid!important ;
}
.project-tab .nav-link, .justify-content-center .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: rgba(255, 255, 255, 0.534) !important;
  font-size: 20px !important;
  font-weight: 300 !important;
}
.full-page-wrapper {
  width: 100%;
    min-height: 100vh !important;
    padding-top: 0 !important;
}
.navbar .navbar-menu-wrapper .dropdown .dropdown-toggle:after {
  color: white !important;
  font-size: 1rem;
}
.project-tab .nav-link:hover {
  border: none;
  color: white !important;
}
.sidebar .nav:not(.sub-menu) > .nav-item:hover:not(.nav-category):not(.nav-profile) > .nav-link > .mdi {
  color: #1e88e5 !important;
}
.sidebar .nav .nav-item .nav-link i.mdi {
  color: rgba(0, 0, 0, 0.603) !important;
}
.sidebar .nav .nav-item.active .nav-link i.mdi {
  color: #1e88e5 !important;
}

/* readings and graph model width */
.graph-model{
  max-width: 77% !important;
}
/* Apply text wrapping on medium and small screens */
@media (max-width: 992px) { /* md and below */
  .text-wrap-responsive {
    white-space: normal !important; /* Allow text to wrap */
  }
  .profile-page-responsive{
    display: flex; /* Use flexbox to arrange items in a row */
    width: 100%;                  /* Ensure full width */
    flex-direction: column !important; /* Enable horizontal scrolling if content overflows */
    justify-content: space-between; /* Prevent wrapping of content */
    overflow-x: scroll;               /* Allow scrolling if content overflows */
  }
  /* readings and graph model width */
.graph-model{
  max-width: 100% !important;
}
}

/* For larger screens, don't wrap the text */
@media (min-width: 993px) { /* lg and above */
  .text-wrap-responsive {
    white-space: nowrap; /* Prevent text from wrapping */
  }
}

/* Custom styles for small screens */
@media (max-width: 767px) {
  .reading-card-title {
    font-size: 1.2rem;
  }

  .reading-card-subtitle {
    font-size: 1rem;
  }
  
  .line-graph-container {
    margin-top: 1rem;
  }
}

/* Default styles for desktop (already in place) */

/* Make the table scrollable on mobile */
@media (max-width: 768px) {
  .table-theshold {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  .table-theshold tr{
    display: block;
    text-align: center;
    width: 99%;
  }
  .table-theshold td {
    display: block;
    text-align: left;
    width: 100%;
    padding: 10px 5px; /* Add padding for better readability */
  }
  .reading-chart-threshold-title {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }

  .reading-chart-threshold-range-details {
    display: block;
    padding-left: 10px;
  }

  .display-fax-center {
    display: block;
    text-align: left;
  }

  .theshold-No-CarePlan {
    display: inline-block;  /* Ensures "No Care Plan" text is inline and looks nice */
  }
}

/* Styles for large screens */
.button-container-reading {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

/* For mobile and smaller screens */
@media (max-width: 768px) {
  .button-container-reading {
    display: block;
    width: 100%;
  }

  .button-container-reading .btn {
    width: 100%;  /* Make buttons full-width on small screens */
    margin: 10px 0;  /* Add some vertical spacing */
  }
}
